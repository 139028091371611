import {
  GET_PROVERB,
  LOADING_PROVERB,
  PURGE,
  GET_PROVERBS,
  LOADING_PROVERBS,
  SET_PROVERB
} from "../actions/types";
import { REHYDRATE } from "redux-persist/lib/constants";

const INITIAL_STATE = {
  reference: null,
  passage: null,
  previous_day: null,
  day: null,
  day_date: null,
  next_day: null,
  isLoaded: true,
  isLoading: false,
  list: false,
  isListLoaded: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.proverb) {
        return {
          ...state,
          ...action.payload.proverb,
          isLoaded: state.isLoaded,
          isLoading: false
        };
      } else {
        return state;
      }

    case PURGE:
      return {
        ...INITIAL_STATE
      };

    case GET_PROVERB:
      return {
        ...state,
        ...action.payload,
        isLoaded: true,
        isLoading: false
      };

    case LOADING_PROVERB:
      return {
        ...state,
        isLoaded: false,
        isLoading: true
      };

    case GET_PROVERBS:
      return {
        ...state,
        list: action.payload,
        isListLoaded: true,
        isLoading: false
      };

    case SET_PROVERB:
      const reference = "Proverbs " + state.list[action.payload.referenceDay];

      return {
        ...state,
        ...action.payload,
        reference
      };

    case LOADING_PROVERBS:
      return {
        ...state,
        isListLoaded: false,
        isLoading: true
      };

    default:
      return state;
  }
};
