import React from "react";
import Modal from "../common/modal";
import PrayerItemList from "../prayers/list";

export default function(props) {
  return (
    <Modal name="Prayer List" as={props.as} label={props.label}>
      <div className="content">
        <PrayerItemList readonly />
      </div>
    </Modal>
  );
}
