import React from "react";
import { Icon, Modal } from "semantic-ui-react";
import PassageSegment from "./passageSegment";

export default function PassageModal(props) {
  return (
    <Modal className="standard" open={!!props.reference} onClose={props.onClose}>
      <Modal.Header className="clearfix">
        <div className="float-left">{props.reference}</div>
        <Icon size="small" onClick={props.onClose} name="close" className="float-right clickable"/> 
      </Modal.Header>
      <Modal.Content>
        <PassageSegment
          reference={props.reference}
          name={props.name}
        />
      </Modal.Content>
    </Modal>
  );
}

