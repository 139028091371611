import React from "react";
import { connect } from "react-redux";

import { fetchPassage, updatePassageView } from "../../actions/verses";

class PassageSegment extends React.Component {
  static defaultProps = {
    name: "passage_modal",
    showReference: false
  };

  componentDidMount = () => {
    this.updatePassage();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.reference !== this.props.reference) {
      this.updatePassage();
    }
  };

  getPassageView = () => {
    return (
      this.props.passageView[this.props.name] || {
        isLoaded: false,
        reference: null,
        passage: null
      }
    );
  };

  updatePassage = () => {
    const reference = this.props.reference;
    const passage = this.props.passages[reference];

    if (!reference) {
      return;
    }

    if (passage) {
      this.props.updatePassageView(this.props.name, {
        isLoaded: true,
        reference: reference,
        passage: passage
      });
    } else {
      this.props.fetchPassage(reference, this.props.name);
    }
  };

  renderPassage = () => {
    if (this.getPassageView().isLoaded) {
      return (
        <div>
          <div>{this.getPassageView().reference}</div>
          <div
            dangerouslySetInnerHTML={{ __html: this.getPassageView().passage }}
          />
          >
        </div>
      );
    }
    return null;
  };

  renderHeader = () => {
    if (this.props.showReference) {
      return <div className="ui header">{this.getPassageView().reference}</div>;
    }

    return null;
  };

  render() {
    const loading = this.getPassageView().isLoaded ? "" : "loading";
    return (
      <div>
        {this.renderHeader()}
        <div
          className={"ui basic segment " + loading}
          dangerouslySetInnerHTML={{ __html: this.getPassageView().passage }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ passages, passageView }) => {
  return {
    passages,
    passageView
  };
};

export default connect(
  mapStateToProps,
  { fetchPassage, updatePassageView }
)(PassageSegment);
