import React from "react";
import { Link } from "react-router-dom";
import { signOut } from "../../actions/auth";
import { connect } from "react-redux";
import LoginButton from "./loginButton";
import { Icon, Image } from "semantic-ui-react";

class AppHeader extends React.Component {
  handleLogout = () => {
    this.props.signOut(this.props.auth.tokenId);
  };

  renderSignedIn = () => {
    return (
      <div>
        <div className="ui fixed menu">
          <Link to="/" className="header item">
            <Image className="header-logo" src="/assets/logo_t_24.png" alt="[Logo]" />
            <span className="computer-only">&nbsp;&nbsp;be transformed</span>
          </Link>
          <Link to="/today" className="item">
            <Icon name="tasks" size="large"/>
            <span className="computer-only">Today</span>
          </Link>
          <Link to="/verses" className="item">
            <Icon name="bookmark" size="large"/>
            <span className="computer-only">Verses</span>
          </Link>
          <Link to="/prayers" className="item">
            <Icon name="fire" size="large"/>
            <span className="computer-only">Prayers</span>
          </Link>
          <Link to="/affirmations" className="item">
            <Icon name="quote left" size="large"/>
            <span className="computer-only">Affirmations</span>
          </Link>
          <LoginButton clearPersistor={this.props.clearPersistor} />
        </div>
      </div>
    );
  };

  renderSignedOut = () => {
    return (
      <div>
        <div className="ui fixed menu">
            <Link to="/" className="header item">
            <Image className="header-logo" src="/assets/logo_t_24.png" alt="[Logo]" />
            <span className="computer-only">
              &nbsp;&nbsp;&nbsp;be transformed
            </span>
          </Link>

          <LoginButton clearPersistor={this.props.clearPersistor} />
        </div>
      </div>
    );
  };

  render() {
    return this.props.auth.isSignedIn
      ? this.renderSignedIn()
      : this.renderSignedOut();
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(
  mapStateToProps,
  { signOut }
)(AppHeader);
