import React from "react";
import { connect } from "react-redux";

class Index extends React.Component {
  render() {
    const welcomeText = !this.props.auth.isSignedIn
      ? (
          <p>
            Please log in or register for your free account in order to access
            daily discipleship task tracking, verse memorization, daily proverbs wisdom, 
            prayer list tools, and more.
          </p>
        )
      : null;

    return (
      <div>
        <h1>tools for discipleship.</h1>
        <p>Welcome!</p>
        
        {welcomeText}

        <div className="ui segment">
          <small>
            Do not be conformed to this world, but be transformed by the renewal
            of your mind, that by testing you may discern what is the will of
            God, what is good and acceptable and perfect.
          </small>
          <p>
            <small>Romans 12:2 ESV</small>
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps)(Index);
