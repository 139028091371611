import React from "react";
import { Button, Segment, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { getVerses } from "../../actions/verses";
import PassageSegment from "./passageSegment";
import _ from "lodash";

class FavoriteVerse extends React.Component {
  state = {
    reference: null,
    versePosition: 0,
    verses: []
  };

  componentDidMount() {
    if (this.props.verses.length) {
      this.shuffleVerses();
    } else {
      if (!this.props.verses.isLoading) {
        this.props.getVerses();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.verses.length !== this.props.verses.length) {
      this.shuffleVerses();
    }
  }

  shuffleVerses = () => {
    this.setState({
      verses: _.shuffle(
        this.props.verses.filter(verse => {
          return verse.favorite;
        })
      )
    });
  };

  setPosition = pos => {
    this.setState({
      versePosition: pos
    });
  };

  getCurrentReference = () => {
    let pos = this.state.versePosition;
    if (!this.state.verses.length) {
      return null;
    }
    return this.state.verses[pos].reference;
  };

  nextVerse = () => {
    const pos = this.state.versePosition;
    const last = this.state.verses.length - 1;
    const newPos = pos >= last ? 0 : pos + 1;
    this.setPosition(newPos);
  };

  previousVerse = () => {
    const pos = this.state.versePosition;
    const last = this.state.verses.length - 1;
    this.setPosition(pos ? pos - 1 : last);
  };

  renderPassage = () => {
    return (
      <PassageSegment
        maxHeight={this.props.maxInnerHeight}
        reference={this.getCurrentReference()}
        name="favorite"
      />
    );
  };

  render() {
    if (!this.state.verses) {
      return null;
    }

    return (
      <Segment className="today">
        <Segment.Inline className="clearfix">
          <h3 className="float-left">Favorite Verses</h3>
          <Button size="small" className="float-right" onClick={this.nextVerse} icon="chevron right" />
          <Divider clearing />
        </Segment.Inline>
        <div className="favorite-passage">
          <h4>{this.getCurrentReference()}</h4>
          {this.renderPassage()}
        </div>
      </Segment>
    );
  }
}

const mapStateToProps = ({ verses }) => {
  return {
    verses: verses.isLoaded ? verses.userVerses : [],
    isLoading: verses.isLoading
  };
};

export default connect(
  mapStateToProps,
  { getVerses }
)(FavoriteVerse);
