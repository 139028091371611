import React from "react";
import { Segment, Icon } from "semantic-ui-react";
import VerseList from "../verses/list";
import RedirectUnauthorized from "../common/redirectUnauthorized";
import { connect } from "react-redux";
import { getVerses } from "../../actions/verses";
import RefreshHeader from "../common/refreshHeader";
// import InfoPopup from "../common/infoPopup";
// import InfoAccordian from "../common/infoAccordian";

class Verses extends React.Component {
  refresh = () => {
    this.props.getVerses();
  };

  render() {
    // const infoText = 
    //   `Here you can track verses that are meaningful to you, 
    //   save them as favorites, 
    //   and mark them for memorization work.`;

    return (
      <div>
        <RedirectUnauthorized />

        <div>
          <div className="clearfix">
            <RefreshHeader title="Verses" onClick={this.refresh} className="float-left" />
            {/* <InfoPopup text={infoText} size="large" className="float-right" /> */}
          </div>

          <Segment style={{maxWidth: "400px"}}>
            <VerseList showActions={true} filter={null} />
          </Segment>

          <Segment style={{maxWidth: "400px"}}>
            <div className="ui horizontal small divided list">
            <div className="item">
              <Icon name="bookmark"/> Memorization
              </div>
            <div className="item">
              <Icon name="heart"/> Favorite
            </div>
            </div>
          </Segment>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { getVerses }
)(Verses);
