import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = "/api/auth/login";
export const LOGOUT_URL = "/api/auth/logout";
export const REGISTER_URL = "/api/auth/register";
export const FORGOT_PASSWORD_URL = "/api/auth/password/request";
export const RESET_PASSWORD_URL = "/api/auth/password/reset";

export const PROVERB_URL = "/api/v1/passages/dailyproverb";
export const PROVERBS_URL = "/api/v1/proverbs";
export const PASSAGES_URL = "/api/v1/passages";
export const VERSES_URL = "/api/v1/verses";
export const PRAYERS_URL = "/api/v1/prayeritems";
export const CURRENT_TASKS_URL = "/api/v1/currenttasks";
export const TASKS_URL = "/api/v1/tasks";
export const AFFIRMATIONS_URL = "/api/v1/affirmations";

class AppApi {
  static instance;

  static getInstance() {
    if (!this.instance) {
      this.instance = new AppApi({ baseURL });
    }

    return this.instance;
  }

  constructor(options) {
    this.client = axios.create({
      baseURL: options.baseURL,
      headers: {
        Accept: "application/json"
      }
    });

    this.getToken = null;
  }

  setTokenGetter = getToken => {
    this.getToken = getToken;
  };

  request = config => {
    const token = this.getToken && this.getToken();

    if (token) {
      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return this.client.request(config);
  };

  get = (url, config = {}) => {
    return this.request({ ...config, method: "GET", url });
  };

  post = (url, data, config = {}) => {
    return this.request({ ...config, method: "POST", url, data });
  };

  delete = (url, data, config = {}) => {
    return this.request({ ...config, method: "DELETE", url, data });
  };

  put = (url, data, config = {}) => {
    return this.request({ ...config, method: "PUT", url, data });
  };

  logout = tokenId => {
    return this.post(LOGOUT_URL, { tokenId });
  };
}

export const client = AppApi.getInstance();
