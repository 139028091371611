import React from "react";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { getAffirmations } from "../../actions/affirmations";
import Modal from "../common/modal";
import _ from "lodash";

class AffirmationReviewModal extends React.Component {
  state = {
    affirmation: null,
    affirmationPosition: 0,
    shuffled: [],
  };

  componentDidMount() {
    if (!this.state.affirmations || !this.state.affirmations.length) {
      this.props.getAffirmations();
    }
    
    if (!this.state.shuffled.length) {
      this.setState({shuffled: _.shuffle(this.props.affirmations)});
    }
  }

  setPosition = pos => {
    this.setState(() => {
      return {
        affirmationPosition: pos,
      };
    });
  };

  getCurrentAffirmation = () => {
    let pos = this.state.affirmationPosition;
    if (!this.state.shuffled.length) {
      return null;
    }

    return this.state.shuffled[pos].affirmation;
  };

  nextAffirmation = () => {
    const pos = this.state.affirmationPosition;
    const last = this.state.shuffled.length - 1;
    this.setPosition(pos === last ? 0 : pos + 1);
  };

  previousAffirmation = () => {
    const pos = this.state.affirmationPosition;
    const last = this.state.shuffled.length - 1;
    this.setPosition(pos ? pos - 1 : last);
  };

  render() {
    return (
      <Modal name="Affirmations" as={this.props.as} label={this.props.label}>
        <div className="content">
          {this.getCurrentAffirmation()}
        </div>
        <div className="actions">
          <Button onClick={this.previousAffirmation} icon="chevron left" />
          <Button onClick={this.nextAffirmation} icon="chevron right" />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ affirmations }) => {
  return {
    affirmations: affirmations.isLoaded
      ? affirmations.items
      : []
  };
};

export default connect(
  mapStateToProps,
  { getAffirmations }
)(AffirmationReviewModal);
