import {
  GET_PRAYERS,
  ADD_PRAYER,
  LOADING_PRAYERS,
  DELETE_PRAYER,
  SAVE_PRAYER
} from "./types";
import { PRAYERS_URL, client as appApi } from "../api/app";

export const setPrayersLoaded = (isLoaded, isLoading) => {
  return {
    type: LOADING_PRAYERS,
    payload: { isLoaded, isLoading }
  };
};

export const getPrayers = () => async dispatch => {
  dispatch({
    type: LOADING_PRAYERS,
    payload: { isLoaded: false, isLoading: true }
  });

  const response = await appApi.get(PRAYERS_URL);

  dispatch({
    type: GET_PRAYERS,
    payload: response.data.data
  });
};

export const addPrayer = prayer => async dispatch => {
  dispatch({
    type: ADD_PRAYER,
    payload: prayer
  });
};

export const savePrayer = (prayer, store = false) => async dispatch => {
  dispatch({
    type: SAVE_PRAYER,
    payload: prayer
  });

  if (store) {
    prayer["_method"] = "PUT";
    await appApi.post(`${PRAYERS_URL}/${prayer.id}`, prayer);
  }
};

export const deletePrayer = prayer => async dispatch => {
  dispatch({
    type: DELETE_PRAYER,
    payload: prayer
  });

  await appApi.post(`${PRAYERS_URL}/${prayer.id}`, { _method: "DELETE" });
};
