import React from "react";
import RedirectUnauthorized from "../common/redirectUnauthorized";
import { connect } from "react-redux";
import { getTasks } from "../../actions/tasks";
import { Button } from "semantic-ui-react";
import TaskList from "../tasks";
import TaskModal from "../tasks/TaskModal";
import RefreshHeader from "../common/refreshHeader";

class Tasks extends React.Component {
  state = {
    modalState: {
      open: false,
      item: {}
    }
  };

  newItemDefaults = {
    name: "Read the Bible more",
    activity: "read",
    link: "https://www.bible.com/users/me/reading-plans",
    active: true
  };

  closeModal = () => {
    this.setState({ modalState: { open: false } });
  };

  openModal = data => {
    this.setState({
      modalState: {
        ...data,
        open: true
      }
    });
  };

  editItem = item => {
    this.openModal({ type: "edit", item });
  };

  newItem = () =>
    this.openModal({ type: "new", item: { ...this.newItemDefaults } });

  refresh = () => {
    this.props.getTasks();
  };

  render() {
    return (
      <div>
        <RedirectUnauthorized />

        <div>
          <RefreshHeader title="Tasks" onClick={this.refresh} />

          <div className="ui aligned small header">
            <Button onClick={this.newItem}>
              Add Item
            </Button>
          </div>

          <TaskList handleEdit={this.editItem} />
        </div>
        <TaskModal
          open={this.state.modalState.open}
          type={this.state.modalState.type}
          handleClose={this.closeModal}
          initialValues={this.state.modalState.item}
        />
      </div>
    );
  }
}

export default connect(
  null,
  { getTasks }
)(Tasks);
