import React from "react";
import { Link } from "react-router-dom";
import { signOut } from "../../actions/auth";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";

class LoginButton extends React.Component {
  handleLogout = () => {
    this.props.signOut(this.props.auth.tokenId);

    if (this.props.clearPersistor) {
      this.props.clearPersistor();
    }
  };

  render() {
    if (this.props.auth.isSignedIn) {
      return (
        <div className="right menu">
          <div onClick={this.handleLogout} className="item link">
            <Icon name="sign-out" size="large" />
            <span className="computer-only">Logout</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="right menu">
          <Link to="/register" className="item">
            Register
          </Link>
          <Link to="/login" className="item">
            Login
          </Link>
        </div>
      );
    }
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(
  mapStateToProps,
  { signOut }
)(LoginButton);
