import React from "react";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { getVerses, saveVerse } from "../../actions/verses";
import PassageSegment from "../passages/passageSegment";
import Modal from "../common/modal";
import _ from "lodash";

class VerseReviewModal extends React.Component {
  state = {
    reference: null,
    versePosition: 0,
    shuffled: [],
    showPassage: false
  };

  componentDidMount = () => {
    this.setState({shuffled: _.shuffle(this.props.verses)});
  }

  setPosition = pos => {
    this.setState(() => {
      return {
        versePosition: pos,
        showPassage: false
      };
    });
  };

  getCurrentReference = () => {
    let pos = this.state.versePosition;
    if (!this.state.shuffled.length) {
      return null;
    }
    return this.state.shuffled[pos].reference;
  };

  nextVerse = () => {
    const pos = this.state.versePosition;
    const last = this.state.shuffled.length - 1;
    this.setPosition(pos === last ? 0 : pos + 1);
  };

  previousVerse = () => {
    const pos = this.state.versePosition;
    const last = this.state.shuffled.length - 1;
    this.setPosition(pos ? pos - 1 : last);
  };

  toggleShow = () => {
    this.setState(() => {
      return {
        showPassage: !this.state.showPassage
      };
    });
  };

  renderPassage = () => {
    if (!this.state.showPassage) {
      return (
        <div className="ui segment basic">
          <em>(Reveal below)</em>
        </div>
      );
    }

    return (
      <PassageSegment
        reference={this.getCurrentReference()}
        name={this.props.name}
      />
    );
  };

  render() {
    const showIcon = this.state.showPassage ? "eye slash outline" : "eye";
    const showLabel = this.state.showPassage ? "Hide verse" : "Show verse";
    return (
      <Modal name={this.getCurrentReference()} as={this.props.as} label={this.props.label}>
        <div className="content">
          {this.renderPassage()}
        </div>
        <div className="actions">
          <Button onClick={this.toggleShow} icon={showIcon} content={showLabel} />
          <Button onClick={this.previousVerse} icon="chevron left" />
          <Button onClick={this.nextVerse} icon="chevron right" />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ verses }) => {
  return {
    verses: verses.isLoaded
      ? verses.userVerses.filter(verse => {
          return verse.memorization;
        })
      : []
  };
};

export default connect(
  mapStateToProps,
  { getVerses, saveVerse }
)(VerseReviewModal);
