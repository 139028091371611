import React from "react";
import Modal from "../common/modal";
import { Icon } from "semantic-ui-react";

export default function(props) {
  const link = props.link
    ? <a href={props.link} target="_new"> {props.label} &nbsp; <Icon name="external alternate" /> </a>
    : null;

  return (
    <Modal name={props.label} as={props.as} label={props.label}>
      <div className="content">
        <p>
          {props.description}
        </p>
        <p>
          {link}
        </p>
      </div>
    </Modal>
  );
}
