import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Icon, Segment, Grid, Divider, Popup } from "semantic-ui-react";
import { getCurrentTasks, saveCurrentTask } from "../../actions/currentTasks";
import ReviewVerseModal from "./VerseReviewModal";
import AffirmationReviewModal from "./AffirmationReviewModal";
import ProverbReviewModal from "./ProverbReviewModal";
import PrayerReviewModal from "./PrayerReviewModal";
import GenericTaskModal from "./genericTaskModal";
import RefreshHeader from "../common/refreshHeader";

class CurrentTaskList extends React.Component {
  state = {
    selectedItem: false
  };

  componentDidMount() {
    if (!this.props.auth.isSignedIn) {
      return;
    }

    window.addEventListener("focus", this.init);

    this.init();
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.isSignedIn && !prevProps.auth.isSignedIn) {
      this.init();
    }
  }

  componentWillUnmount() {
      window.removeEventListener("focus", this.init)
  }

  init = () => {
    if (!this.props.tasks.list || !this.props.tasks.list.length) {
      if (!this.props.tasks.isLoading) {
        this.props.getCurrentTasks();
      }
    } else {
      const today = moment();
      const tasksDate = moment(this.props.tasks.date);
      if (today.format("MMDD") !== tasksDate.format("MMDD")) {
        this.props.getCurrentTasks();
      }
    }
  };

  refresh = () => {
    this.props.getCurrentTasks();
  };

  handleCheck = task => {
    if (task.completed) {
      return;
    }

    const updatedTask = {
      ...task,
      completed: true
    };

    this.props.saveCurrentTask(updatedTask, true);
  };

  renderGenericTask = (task, id, description) => {
    return <GenericTaskModal as="link" label={task.name} taskId={id} link={task.link} description={description}/>
  }

  renderDescription = (task, id) => {
    switch (task.activity) {
      case "pray":
        return (
          <b>
            <PrayerReviewModal as="link" label={task.name} taskId={id} />
          </b>
        );

      case "memorize":
        return (
          <b>
            <ReviewVerseModal as="link" label={task.name} taskId={id} />
          </b>
        );

      case "wisdom":
        return (
          <b>
            <ProverbReviewModal as="link" label={task.name} taskId={id} />
          </b>
        );

      case "affirmations":
        return (
          <b>
            <AffirmationReviewModal as="link" label={task.name} taskId={id} />
          </b>
        );
        
      case "read":
        return (
          <b>
            <a href={task.link} target="_new"> 
              {task.name} &nbsp; 
            <Icon name="external alternate" /> 
            </a> 
          </b>
        );

      case "link":
        return (
          <b>
            <a href={task.link} target="_new"> 
              {task.name} &nbsp; 
            <Icon name="external alternate" /> 
            </a> 
          </b>
        );
      
      default:
        return <b> {task.name} </b>;
        
    }
  };

  renderItemsAsGrid = () => {
    const tasks = this.props.tasks.list;
    return tasks.map(item => {
      const icon = (
        <Icon
          name={"square outline" + (item.completed ? " check" : "")}
          size="large"
          link
          onClick={() => this.handleCheck(item)}
        />
      );

      const popupLabels = (
        <div className="ui labels">
          <div className="ui tiny label">
            <span>Current Streak</span>
            <div className="detail">{item.task.current_streak}</div>
          </div>
          <div className="ui tiny label">
            <span>Longest Streak</span>
            <div className="detail">{item.task.longest_streak}</div>
          </div>
          <div className="ui tiny label">
            <span>Total Done</span>
            <div className="detail">{item.task.count}</div>
          </div>
        </div>
      );

      const label = (
        <div className="ui tiny label olive clickable">
          <span className="computer-only">Streak</span>
          <div className="computer-only detail">{item.task.current_streak}</div>
          <div className="except-computer">{item.task.current_streak}</div>
        </div>
      );

      return (
        <Grid.Row key={item.id}>
          <Grid.Column width={12}>
            {icon}
            {this.renderDescription(item.task, item.id)}
            </Grid.Column>
            <Grid.Column width={4}>
            <Popup wide
              content={popupLabels} 
              trigger={label} 
              size="tiny"
              position='bottom right'
            />
          </Grid.Column>
        </Grid.Row>
      );
    });
  };

  render() {
    if (!this.props.auth.isSignedIn) {
      return null;
    }

    if (this.props.tasks.isLoading) {
      return (
        <div className="active ui inverted dimmer">
          <div className="ui text loader">Loading</div>
        </div>
      );
    }

    const { tasks } = this.props;
    const date = moment(tasks.date || undefined).format("MMMM Do");

    if (!tasks.list || !tasks.list.length) {
      return (
        <div className="active ui inverted dimmer">
          <div className="ui text loader">Loading</div>
        </div>
      );
    }

    return (
      <Segment className="today">
        <Segment.Inline className="clearfix">
          <RefreshHeader as="h3" className="float-left" title={date} onClick={this.refresh} />
          <div className="float-right">
            <Link to="/tasks">
              <Icon link name="setting" />
            </Link>
          </div>
          <Divider clearing />
        </Segment.Inline>
        <Grid columns={2}>
          {this.renderItemsAsGrid()}
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = ({ currentTasks, auth }) => {
  return { tasks: currentTasks, auth };
};

export default connect(
  mapStateToProps,
  { getCurrentTasks, saveCurrentTask }
)(CurrentTaskList);
