import React from "react";
import { Button, Modal as SemanticModal, Icon } from "semantic-ui-react";

class Modal extends React.Component {
  state = {
    isOpen: false,
  };

  setOpen = isOpen => {
    this.setState({isOpen});
  };

  renderTrigger = () => {
    switch (this.props.as) {
      case "link":
        return (
          <span
            className="clickable"
            onClick={() => this.setOpen(true)}
            style={{ color: "#4183c4" }}
          >
            {this.props.label}
          </span>
        );
      case "icon":
        return (
          <Icon
            name={this.props.label}
            link
            onClick={() => this.setOpen(true)}
            style={{ color: "#4183c4" }}
            size={this.props.size ? this.props.size : null}
          />
        );
      case "label":
      default:
        return (
          <Button onClick={() => this.setOpen(true)}>{this.props.label}</Button>
        );
    }
  };

  render() {
    return (
      <span>
        {this.renderTrigger()}
        <SemanticModal open={this.state.isOpen} onClose={() => this.setOpen(false)} className="standard">
          <div className="header clearfix">
            <div className="float-left">{this.props.name}</div> 
            <Icon size="small" onClick={() => this.setOpen(false)} name="close" className="float-right clickable"/> 
          </div>
          {this.props.children}
        </SemanticModal>
      </span>
    );
  }
}

export default Modal;
