import { SAVE_PASSAGE, PURGE } from "../actions/types";

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_PASSAGE:
      const passages = { ...state };
      passages[action.payload.reference] = action.payload.passage;
      return passages;

    case PURGE:
      return {
        ...INITIAL_STATE
      }

    default:
      return state;
  }
};
