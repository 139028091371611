import {
  GET_VERSES,
  ADD_VERSE,
  LOADING_VERSES,
  PURGE,
  DELETE_VERSE,
  SAVE_VERSE
} from "../actions/types";
import { REHYDRATE } from "redux-persist/lib/constants";

const INITIAL_STATE = {
  userVerses: [],
  isLoaded: false,
  isLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.verses) {
        return {
          ...state,
          ...action.payload.verses,
        };
      } else {
        return state;
      }

    case PURGE:
      return {
        ...INITIAL_STATE
      };

    case LOADING_VERSES:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        isLoaded: action.payload.isLoaded
      };

    case GET_VERSES:
      return {
        isLoaded: true,
        isLoading: false,
        userVerses: action.payload
      };

    case ADD_VERSE:
      return {
        isLoaded: true,
        isLoading: false,
        userVerses: [...state.userVerses, action.payload]
      };

    case DELETE_VERSE:
      return {
        isLoaded: state.isLoaded,
        isLoading: false,
        userVerses: state.userVerses.filter(verse => {
          return verse.id !== action.payload.id;
        })
      };

    case SAVE_VERSE:
      return {
        isLoaded: state.isLoaded,
        isLoading: false,
        userVerses: state.userVerses.map(verse => {
          if (verse.id === action.payload.id) {
            verse = action.payload;
          }

          return verse;
        })
      };

    default:
      return state;
  }
};
