import React from "react";
import { Icon } from "semantic-ui-react";
import RedirectUnauthorized from "../common/redirectUnauthorized";
import { connect } from "react-redux";
import { getPrayers } from "../../actions/prayers";
import { Button } from "semantic-ui-react";
import PrayerItemList from "../prayers/list";
import ItemModal from "../prayers/itemModal";
import RefreshHeader from "../common/refreshHeader";

class Prayers extends React.Component {
  state = {
    modalState: {
      open: false,
      item: {
        order: 100
      }
    }
  };

  closeModal = () => {
    this.setState({ modalState: { open: false } });
  };

  openModal = data => {
    this.setState({
      modalState: {
        ...data,
        open: true
      }
    });
  };

  editItem = item => {
    this.openModal({
      type: "edit",
      item: {
        order: 100,
        ...item
      }
    });
  };

  refresh = () => {
    this.props.getPrayers();
  };

  render() {
    return (
      <div>
        <RedirectUnauthorized />

        <div>
          <RefreshHeader title="Prayers" onClick={this.refresh} />

          <div className="ui aligned small header">
            <Button onClick={() => this.openModal({ type: "new" })}>
              <Icon name="add" />
              Add Item
            </Button>
          </div>
        </div>

        <PrayerItemList
          handleEdit={item => {
            this.openModal({ type: "edit", item });
          }}
          handleAdd={() => this.openModal({ type: "new" })}
        />

        <ItemModal
          open={this.state.modalState.open}
          type={this.state.modalState.type}
          handleClose={this.closeModal}
          initialValues={this.state.modalState.item}
        />
      </div>
    );
  }
}

export default connect(
  null,
  { getPrayers }
)(Prayers);
