import {
  GET_PROVERB,
  LOADING_PROVERB,
  SAVE_PASSAGE,
  LOADING_PROVERBS,
  GET_PROVERBS,
  SET_PROVERB
} from "./types";
import { client as appApi, PROVERB_URL, PROVERBS_URL } from "../api/app";
import moment from "moment";

export const getDailyProverb = day => async dispatch => {
  const arg = day ? "/" + day : "";

  dispatch({
    type: LOADING_PROVERB
  });

  const response = await appApi.get(`${PROVERB_URL}${arg}`);

  dispatch({
    type: SAVE_PASSAGE,
    payload: {
      reference: response.data.reference,
      passage: response.data.passage
    }
  });

  dispatch({
    type: GET_PROVERB,
    payload: response.data
  });
};

export const setProverb = data => {
  return {
    type: SET_PROVERB,
    payload: createProverbPayload(data)
  };
};

export const getProverbs = data => async dispatch => {
  dispatch({
    type: LOADING_PROVERBS
  });

  const response = await appApi.get(`${PROVERBS_URL}`);

  dispatch({
    type: GET_PROVERBS,
    payload: response.data
  });

  dispatch({
    type: SET_PROVERB,
    payload: createProverbPayload(data)
  });
};

const createProverbPayload = data => {
  const newDate = data.moment ? data.moment : moment().dayOfYear(data.day);
  const newDay = data.day ? data.day : data.moment.dayOfYear();
  const daysInYear = 365; //+ (newDate.isLeapYear() ? 1 : 0);

  return {
    referenceDay: newDay - 1,
    previous_day: newDay > 1 ? newDay - 1 : daysInYear,
    day: newDay,
    day_date: newDate.format("YYYY-MM-DD"),
    next_day: newDay < daysInYear ? newDay + 1 : 1
  };
};
