import React from "react";
import { connect } from "react-redux";
import { getPrayers, savePrayer } from "../../actions/prayers";
import { Accordion, Grid, Segment, Header } from "semantic-ui-react";
import Markdown from 'react-markdown';
import PrayerItemTrash from "./trash";
import EditPrayerItem from "./edit";
import MovePrayerItem from "./move";

class PrayerItemList extends React.Component {
  componentDidMount() {
    if (!this.props.prayers || (!this.props.prayers.isLoaded && !this.props.prayers.isLoading)) {
      this.props.getPrayers();
    }
  }
  
  renderPrayerItems = () => {
    return this.props.prayers.items.map((item, i) => {
      const content = item.details ? (
        <Markdown source={item.details.replace(/\n/g, "  \n")}/>
      ) : <small><i>No details</i></small>;

      const panels = [
        {
          key: `panel-${item.id}`,
          title: { content: <Header size="small" as="span" content={item.item} /> },
          content: { content }
        }
      ];

      if (this.props.readonly) {
        return (
          <Grid.Row key={item.id}>
            <Grid.Column width={16}>
              <Accordion panels={panels} defaultActiveIndex={-1} />
            </Grid.Column>
          </Grid.Row>
        );
      } else {
        return (
          <Grid.Row key={item.id}>
            <Grid.Column width={10}>
              <Accordion panels={panels} defaultActiveIndex={-1} />
            </Grid.Column>
            <Grid.Column width={6} only="computer tablet" textAlign="right">
              <div className="extra-vertical-padding">
                <EditPrayerItem onClick={() => this.props.handleEdit(item)} />
                <PrayerItemTrash prayer={item} />
                <MovePrayerItem i={i} />
              </div>
            </Grid.Column>
            <Grid.Column width={6} only="mobile">
              <div className="extra-vertical-padding">
                <EditPrayerItem onClick={() => this.props.handleEdit(item)} />
                <PrayerItemTrash prayer={item} />
              </div>
            </Grid.Column>
          </Grid.Row>
        );  
      }
    });
  };

  renderContent = () => {
    if (!this.props.prayers.items || this.props.prayers.isLoading) {
      return (
        <div className="active ui inverted dimmer">
          <div className="ui text loader">Loading</div>
        </div>
      );
    } else {
      const columns = this.props.readonly ? 1 : 2;

      return (
        <Grid columns={columns} divided="vertically" stackable>
          {this.renderPrayerItems()}
        </Grid>
      );
    }
  };

  render() {
    if (!this.props.prayers.isLoading && this.props.prayers.items && !this.props.prayers.items.length) {
      return null;
    } else if (this.props.readonly) {
        return this.renderContent(); 
    } else {
      return (
        <Segment style={{ minHeight: "100px" }}>{this.renderContent()}</Segment>
      );
    }
  }
}

const mapStateToProps = ({ prayers, auth }) => {
  return { prayers, auth };
};

export default connect(
  mapStateToProps,
  { getPrayers, savePrayer }
)(PrayerItemList);
