import React from "react";
import { connect } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { addPrayer, savePrayer, setPrayersLoaded } from "../../actions/prayers";
import { client as appApi, PRAYERS_URL } from "../../api/app";

class ItemModal extends React.Component {
  onSubmit = async formValues => {
    try {
      this.props.setPrayersLoaded(false);
      const { id } = formValues;
      const url = PRAYERS_URL + (id ? "/" + id : "");

      if (id) {
        formValues["_method"] = "PUT";
      }

      const response = await appApi.post(url, formValues);

      if (id) {
        this.props.savePrayer(response.data.data);
      } else {
        this.props.addPrayer(response.data.data);
      }

      this.props.reset();
      this.props.handleClose();
    } catch (error) {
      this.props.setPrayersLoaded(true);
      throw new SubmissionError({ _error: "Couldn't add Prayer." });
    }
  };

  renderInput = ({ input, placeholder, meta, type }) => {
    const inputField =
      type === "textarea" ? (
        <textarea {...input} placeholder={placeholder} />
      ) : (
        <input {...input} type={type} placeholder={placeholder} />
      );

    return (
      <div className="ui fluid input field">
        {inputField}
        {this.renderError(meta)}
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderSubmitError(error) {
    if (error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  render() {
    const title = this.props.type === "new" ? "Add Item" : "Edit Item";
    const submitLabel = this.props.type === "new" ? "Add" : "Save";

    return (
      <div>
        <Modal open={this.props.open} onClose={this.props.handleClose}>
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <form
                className="ui form error"
                onSubmit={this.props.handleSubmit(this.onSubmit)}
                autoComplete="off"
              >
                <Field
                  name="item"
                  component={this.renderInput}
                  type="text"
                  placeholder="Prayer Item Title"
                  label="Title"
                />
                <Field
                  name="details"
                  component={this.renderInput}
                  type="textarea"
                  placeholder="Item details - enter one per line"
                  label="Details"
                />
                {this.renderSubmitError(this.props.error)}
              </form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.props.handleClose}>Cancel</Button>
            <Button primary onClick={this.props.handleSubmit(this.onSubmit)}>
              {submitLabel}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const connector = connect(
  null,
  { addPrayer, savePrayer, setPrayersLoaded }
)(ItemModal);

export default reduxForm({
  form: "addPrayer",
  enableReinitialize: true
})(connector);
