import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Redirect } from "react-router-dom";
import { signIn } from "../../actions/auth";
import { client as appApi, RESET_PASSWORD_URL, LOGIN_URL } from "../../api/app";

class ResetPassword extends React.Component {
  renderAttemptError(error) {
    if (error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderInput = ({ input, label, meta, type }) => {
    return (
      <div className="field">
        <label>{label}</label>
        <input {...input} type={type} />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = async formValues => {
    try {
      formValues["token"] = this.props.location.pathname.split("/").pop();

      await appApi.post(RESET_PASSWORD_URL, formValues);
      const response = await appApi.post(LOGIN_URL, formValues);
      this.props.signIn(response.data.success);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        throw new SubmissionError({ _error: error.response.data.error });
      }

      throw new SubmissionError({ _error: "An unknown error occured." });
    }
  };

  render() {
    if (this.props.auth.isSignedIn) {
      return <Redirect to="/home" />;
    }

    return (
      <div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="email"
            component={this.renderInput}
            label="Email"
            type="text"
          />
          <Field
            name="password"
            component={this.renderInput}
            label="Password"
            type="password"
          />
          <Field
            name="password_confirmation"
            component={this.renderInput}
            label="Confirm Password"
            type="password"
          />
          <button className="ui button primary">Change Password</button>
          {this.renderAttemptError(this.props.error)}
        </form>
      </div>
    );
  }
}

const validate = formValues => {
  const errors = {};

  if (!formValues.email) {
    errors.email = "Please enter an email address";
  }

  if (!formValues.password) {
    errors.password = "Please enter a password.";
  }

  if (formValues.password !== formValues["password_confirmation"]) {
    errors.c_password = "Passwords do not match";
  }

  return errors;
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

const connector = connect(
  mapStateToProps,
  { signIn }
)(ResetPassword);

export default reduxForm({
  form: "resetPassword",
  validate
})(connector);
