import {
  GET_PRAYERS,
  ADD_PRAYER,
  LOADING_PRAYERS,
  PURGE,
  DELETE_PRAYER,
  SAVE_PRAYER
} from "../actions/types";
import { REHYDRATE } from "redux-persist/lib/constants";

const INITIAL_STATE = {
  items: false,
  isLoaded: false,
  isLoading: false
};

const sortByOrder = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }

  if (a.order > b.order) {
    return 1;
  }

  return 0;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.items) {
        return {
          ...state,
          ...action.payload.items,
          isLoaded: state.isLoaded
        };
      } else {
        return state;
      }

    case PURGE:
      return {
        ...INITIAL_STATE
      };

    case LOADING_PRAYERS:
      return {
        ...state,
        isLoaded: action.payload.isLoaded,
        isLoading: action.payload.isLoading
      };

    case GET_PRAYERS:
      return {
        isLoaded: true,
        isLoading: false,
        items: action.payload.sort(sortByOrder)
      };

    case ADD_PRAYER:
      return {
        isLoaded: true,
        isLoading: false,
        items: [...state.items, action.payload].sort(sortByOrder)
      };

    case DELETE_PRAYER:
      return {
        isLoaded: state.isLoaded,
        isLoading: false,
        items: state.items.filter(item => {
          return item.id !== action.payload.id;
        })
      };

    case SAVE_PRAYER:
      return {
        isLoaded: state.isLoaded,
        isLoading: false,
        items: state.items
          .map(item => {
            if (item.id === action.payload.id) {
              item = action.payload;
            }

            return item;
          })
          .sort(sortByOrder)
      };

    default:
      return state;
  }
};
