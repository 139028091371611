import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { addVerse, setVersesLoaded } from "../../actions/verses";
import { client as appApi, VERSES_URL } from "../../api/app";

class AddVerse extends React.Component {
  onSubmit = async formValues => {
    try {
      this.props.setVersesLoaded(false);
      const response = await appApi.post(VERSES_URL, formValues);
      this.props.addVerse(response.data.data);
      this.props.reset();
    } catch (error) {
      this.props.setVersesLoaded(true);
      throw new SubmissionError({ _error: "Couldn't add verse." });
    }
  };

  renderInput = ({ input, placeholder, meta, type }) => {
    return (
      <div className="ui fluid input field">
        <input {...input} type={type} placeholder={placeholder} />
        {this.renderError(meta)}
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderSubmitError(error) {
    if (error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  render() {
    return (
      <form
        className="ui form error"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        autoComplete="off"
      >
        <Field
          name="verse"
          component={this.renderInput}
          type="text"
          placeholder="Add verse: 'Revelation 1:5', 'rev 4:1-10', 'Ps 23', etc..."
        />
        {this.renderSubmitError(this.props.error)}
      </form>
    );
  }
}

const connector = connect(
  null,
  { addVerse, setVersesLoaded }
)(AddVerse);

export default reduxForm({
  form: "addVerse"
})(connector);
