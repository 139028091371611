import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Popup } from "semantic-ui-react";

class Footer extends React.Component {
  clear = () => {
    this.props.clearPersistor();
    window.location.reload();
  };

  renderClearLink = () => {
    if (process.env.NODE_ENV !== "production") {
      return (
        <div className="ui item">
          <button className="ui red mini button" onClick={this.clear}>
            Clear State
          </button>
        </div>
      );
    }
  };

  render() {
    const email = "info@betransformed.app";
    const contactLink = (
      <Popup 
        content={email} 
        trigger={<a href="mailto:info@betransformed.app" className="item">Email Support</a> } 
      />
    );

    return (
      <div className="ui vertical segment">
        <div className="ui center aligned container">
          <div className="ui horizontal small divided link list">
            <Link to="/about/terms" className="item">
              Terms and Conditions
            </Link>
            <Link to="/about/privacy" className="item">
              Privacy Policy
            </Link>
            {this.props.auth.isSignedIn ? contactLink : null}
          </div>
          {this.renderClearLink()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps)(Footer);
