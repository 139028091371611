import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Redirect } from "react-router-dom";
import { Message, Header } from "semantic-ui-react";
import { client as appApi, FORGOT_PASSWORD_URL } from "../../api/app";

class ForgotPassword extends React.Component {
  state = { successMessage: null };
  renderAttemptError(error) {
    if (error) {
      return (
        <Message error>
          <Header>{error}</Header>
        </Message>
      );
    }
  }

  renderSuccessMessage() {
    if (this.state.successMessage) {
      return (
        <Message
          positive
          header="Processing Request"
          content={this.state.successMessage}
        />
      );
    }
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderInput = ({ input, label, meta, type }) => {
    return (
      <div className="field">
        <label>{label}</label>
        <input {...input} type={type} />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = async formValues => {
    try {
      await appApi.post(FORGOT_PASSWORD_URL, formValues);
      this.setState({
        successMessage:
          "If you entered a valid user account email, an email will be sent shortly with a reset link."
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        throw new SubmissionError({ _error: error.response.data.error });
      }

      throw new SubmissionError({ _error: "An unknown error occured." });
    }
  };

  render() {
    if (this.props.auth.isSignedIn) {
      return <Redirect to="/home" />;
    }

    return (
      <div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="email"
            component={this.renderInput}
            label="Email"
            type="text"
          />
          <button className="ui button primary">Request Password Reset</button>
          {this.renderAttemptError(this.props.error)}
          {this.renderSuccessMessage()}
        </form>
      </div>
    );
  }
}

const validate = formValues => {
  const errors = {};

  if (!formValues.email) {
    errors.email = "Please enter an email address";
  }
  return errors;
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

const connector = connect(
  mapStateToProps,
  null
)(ForgotPassword);

export default reduxForm({
  form: "forgot_password",
  validate
})(connector);
