import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class RedirectUnauthorized extends React.Component {
  render() {
    if (!this.props.auth.isSignedIn) {
      return <Redirect to="/login" />;
    }

    return null;
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps)(RedirectUnauthorized);
