import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Redirect, Link } from "react-router-dom";
import { signIn } from "../../actions/auth";
import { client as appApi, LOGIN_URL } from "../../api/app";

class Login extends React.Component {
  state = {
    loading: false
  };

  renderAttemptError(error) {
    if (error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderInput = ({ input, label, meta, type }) => {
    return (
      <div className="field">
        <label>{label}</label>
        <input {...input} type={type} />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = async formValues => {
    this.setState({loading: true});
    try {
      const response = await appApi.post(LOGIN_URL, formValues);
      this.props.signIn(response.data.success);
    } catch (error) {
      throw new SubmissionError({ _error: "Invalid username or password" });
    } finally {
      this.setState({loading: false})
    }
  };

  render() {
    if (this.props.auth.isSignedIn) {
      return <Redirect to="/today" />;
    }

    if (this.state.loading) {
      return (
        <div className="active ui inverted dimmer">
          <div className="ui text loader">Loading</div>
        </div>
      );
    }
    
    return (
      <div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="email"
            component={this.renderInput}
            label="Email"
            type="text"
          />
          <Field
            name="password"
            component={this.renderInput}
            label="Password"
            type="password"
          />
          <button className="ui button primary">Login</button>
          &nbsp;
          <Link to="/forgot">Forgot your password?</Link>
          {this.renderAttemptError(this.props.error)}
        </form>
      </div>
    );
  }
}

const validate = formValues => {
  const errors = {};

  if (!formValues.email) {
    errors.email = "Please enter an email address";
  }

  if (!formValues.password) {
    errors.password = "Please enter a password";
  }

  return errors;
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

const connector = connect(
  mapStateToProps,
  { signIn }
)(Login);

export default reduxForm({
  form: "login",
  validate
})(connector);
