import {
  GET_VERSES,
  ADD_VERSE,
  LOADING_VERSES,
  SAVE_PASSAGE,
  UPDATE_PASSAGE_VIEW,
  DELETE_VERSE,
  SAVE_VERSE
} from "./types";
import { PASSAGES_URL, VERSES_URL, client as appApi } from "../api/app";

export const setVersesLoaded = (isLoaded, isLoading) => {
  return {
    type: LOADING_VERSES,
    payload: { isLoaded, isLoading }
  };
};

export const getVerses = () => async dispatch => {
  dispatch({
    type: LOADING_VERSES,
    payload: { isLoaded: false, isLoading: true }
  });

  const response = await appApi.get(VERSES_URL);

  dispatch({
    type: GET_VERSES,
    payload: response.data.data
  });
};

export const addVerse = verse => async dispatch => {
  dispatch({
    type: ADD_VERSE,
    payload: verse
  });
};

export const saveVerse = verse => async dispatch => {
  dispatch({
    type: SAVE_VERSE,
    payload: verse
  });

  await appApi.post(`${VERSES_URL}/${verse.id}`, {
    ...verse,
    _method: "PUT"
  });
};

export const deleteVerse = verse => async dispatch => {
  dispatch({
    type: DELETE_VERSE,
    payload: verse
  });

  await appApi.post(`${VERSES_URL}/${verse.id}`, { _method: "DELETE" });
};

export const fetchPassage = (reference, modalName) => async dispatch => {
  if (modalName) {
    dispatch({
      type: UPDATE_PASSAGE_VIEW,
      payload: {
        name: modalName,
        data: {
          isLoaded: false,
          reference: null,
          passage: null
        }
      }
    });
  }

  const response = await appApi.get(`${PASSAGES_URL}/${reference}`);

  dispatch({
    type: SAVE_PASSAGE,
    payload: {
      reference,
      passage: response.data.passage
    }
  });

  if (modalName) {
    dispatch({
      type: UPDATE_PASSAGE_VIEW,
      payload: {
        name: modalName,
        data: {
          isLoaded: true,
          reference: response.data.reference,
          passage: response.data.passage
        }
      }
    });
  }
};

export const updatePassageView = (name, data) => {
  return {
    type: UPDATE_PASSAGE_VIEW,
    payload: { name, data }
  };
};
