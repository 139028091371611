import React from "react";
import RedirectUnauthorized from "../common/redirectUnauthorized";
import CurrentTaskList from "../today/CurrentTaskList";
import FavoriteVerse from "../passages/FavoriteVerse";

class Today extends React.Component {

  render() {
    return (
      <div>
        <RedirectUnauthorized />

        <div>
          <h1> Today </h1>

          <CurrentTaskList header="Tasks" />
          <FavoriteVerse maxInnerHeight="200px" />
        </div>
      </div>
    );
  }
}

export default Today;
