import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import AppHeader from "./header";
import Footer from "./footer";

import Login from "../approutes/login";
import Register from "../approutes/register";
import ForgotPassword from "../approutes/forgot";
import ResetPassword from "../approutes/reset";
import Index from "../approutes/index";
import Verses from "../approutes/verses";
import Prayers from "../approutes/prayers";
import Tasks from "../approutes/tasks";
import PrivacyPolicy from "../approutes/privacy";
import TermsOfUse from "../approutes/terms";
import Affirmations from "../approutes/affirmations";
import Today from "../approutes/today";

class App extends Component {
   render() {
      return (
        <BrowserRouter basename={process.env.REACT_APP_PATH}>
          <Container fluid style={{ marginTop: "50px" }}>
            <AppHeader/>
  
            <Container text style={{ paddingTop: "20px" }}>
              <Route path="/" exact component={() => <Index />} />
              <Route path="/login" render={() => <Login />} />
              <Route path="/register" render={() => <Register />} />
              <Route path="/forgot" render={() => <ForgotPassword />} />
              <Route
                path="/reset"
                render={({ location }) => <ResetPassword location={location} />}
              />
              <Route path="/about/privacy" component={PrivacyPolicy} />
              <Route path="/about/terms" component={TermsOfUse} />
              <Route path="/affirmations" component={Affirmations} />
              <Route path="/verses" component={Verses} />
              <Route path="/prayers" component={Prayers} />
              <Route path="/tasks" component={Tasks} />
              <Route path="/today" component={Today} />
            </Container>
            <div>&nbsp;</div>
            <Footer clearPersistor={this.props.persistor.purge} />
          </Container>
        </BrowserRouter>
      );
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps)(App);
