import React from "react";
import { PrivacyPolicy as Content } from "../content/privacyPolicy";

class PrivacyPolicy extends React.Component {
  render() {
    return <Content />;
  }
}

export default PrivacyPolicy;
