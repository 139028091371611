import {
  LOADING_CURRENT_TASKS,
  SAVE_CURRENT_TASK,
  GET_CURRENT_TASKS
} from "./types";
import { CURRENT_TASKS_URL, client as appApi } from "../api/app";

export const setCurrentTasksLoaded = (isLoaded, isLoading) => {
  return {
    type: LOADING_CURRENT_TASKS,
    payload: { isLoaded, isLoading }
  };
};

export const getCurrentTasks = () => async dispatch => {
  dispatch({
    type: LOADING_CURRENT_TASKS,
    payload: { isLoaded: false, isLoading: true }
  });

  const response = await appApi.get(CURRENT_TASKS_URL);

  dispatch({
    type: GET_CURRENT_TASKS,
    payload: response.data
  });
};

export const saveCurrentTask = (task, store = false) => async dispatch => {
  dispatch({
    type: SAVE_CURRENT_TASK,
    payload: task
  });

  if (store) {
    task["_method"] = "PUT";
    const response = await appApi.post(`${CURRENT_TASKS_URL}/${task.id}`, task);
    
    dispatch({
      type: SAVE_CURRENT_TASK,
      payload: response.data
    });

  }
};
