import React from "react";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { savePrayer } from "../../actions/prayers";

class MovePrayerItem extends React.Component {
  movePrayerItem = (oldIndex, newIndex) => {
    const { items } = this.props;
    const len = items.length;
    const ordered = [...items];

    if (newIndex > len - 1) {
      newIndex = len - 1;
    }

    if (newIndex < 0) {
      newIndex = 0;
    }

    if (oldIndex === newIndex) {
      return;
    }

    ordered.splice(newIndex, 0, ordered.splice(oldIndex, 1)[0]);

    ordered.forEach((item, i) => {
      if (i === item.order) {
        return;
      }

      this.props.savePrayer(
        {
          ...item,
          order: i
        },
        true
      );
    });
  };

  render() {
    const { i } = this.props;
    return (
      <span>
        <Icon
          className="clickable"
          size="large"
          name="angle up"
          onClick={() => this.movePrayerItem(i, i - 1)}
        />
        <Icon
          className="clickable"
          size="large"
          name="angle down"
          onClick={() => this.movePrayerItem(i, i + 1)}
        />
      </span>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: state.prayers.items
  };
};

export default connect(
  mapStateToProps,
  { savePrayer }
)(MovePrayerItem);
