import React from "react";
import { Icon } from "semantic-ui-react";

class EditAffirmationItem extends React.Component {
  render() {
    return (
      <Icon
        className="clickable"
        size="large"
        name="edit"
        onClick={this.props.onClick}
      />
    );
  }
}

export default EditAffirmationItem;
