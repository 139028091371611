import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../common/modal";
import {
  getProverbs,
  setProverb
} from "../../actions/proverb";
import moment from "moment";
import PassageSegment from "../passages/passageSegment";

export default function(props) {
  const proverb = useSelector(state => state.proverb);
  const dispatch = useDispatch();
  const REFERENCE_NAME = "PROVERB_OF_THE_DAY";

  const setDate = (data) => {
    dispatch(setProverb(data));
  }

  useEffect(() => {
    const today = moment();
    const proverbDate = moment(proverb.day_date);

    if (
      !proverb || (!proverb.list && !proverb.isLoading)
    ) {
      dispatch(getProverbs({ moment: today }));
    } else {
      if (today.format("MMDD") !== proverbDate.format("MMDD")) {
        setDate({ moment: today });
      }
    }
  }, []);

  const header = moment(proverb.day_date || undefined).format("MMMM Do");

  if (!proverb || proverb.isLoading) {
    return (
      <div
        style={{ minHeight: "100px" }}
        className="active ui inverted dimmer"
      >
        <div className="ui text loader">Loading</div>
      </div>
    );
  }

  return (
    <Modal name="Today's Proverb" as={props.as} label={props.label}>
      <div className="content">
        <h3>{proverb.reference}</h3>

        <PassageSegment reference={proverb ? proverb.reference : false} name={REFERENCE_NAME} />
      </div>
  
      <div className="actions">
        <div className="ui pagination menu">
          <div
            className="item link"
            onClick={() =>
              setDate({ day: proverb.previous_day })
            }
          >
            Day {proverb.previous_day}
          </div>
          <div className="active item">{header}</div>
          <div
            className="item link"
            onClick={() => setDate({ day: proverb.next_day })}
          >
            Day {proverb.next_day}
          </div>
        </div>&nbsp;
      </div>
    </Modal>
  );
}
