import {
  LOADING_TASKS,
  GET_TASKS,
  ADD_TASK,
  SAVE_TASK,
  DELETE_TASK,
} from "./types";
import { TASKS_URL, client as appApi } from "../api/app";
import { getCurrentTasks } from './currentTasks';

export const setTasksLoaded = (isLoaded, isLoading) => {
  return {
    type: LOADING_TASKS,
    payload: { isLoaded, isLoading }
  };
};

export const getTasks = () => async dispatch => {
  dispatch({
    type: LOADING_TASKS,
    payload: { isLoaded: false, isLoading: true }
  });

  const response = await appApi.get(TASKS_URL);

  dispatch({
    type: GET_TASKS,
    payload: response.data.data
  });
};

export const addTask = task => async dispatch => {
  dispatch({
    type: ADD_TASK,
    payload: task
  });
};

export const saveTask = (task, store = false) => async dispatch => {
  dispatch({
    type: SAVE_TASK,
    payload: task
  });

  if (store) {
    task["_method"] = "PUT";
    await appApi.post(`${TASKS_URL}/${task.id}`, task);
  }
};

export const deleteTask = task => async dispatch => {
  dispatch({
    type: DELETE_TASK,
    payload: task
  });

  await appApi.post(`${TASKS_URL}/${task.id}`, { _method: "DELETE" });
  
  dispatch(getCurrentTasks());
};
