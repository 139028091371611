import React from "react";
import { connect } from "react-redux";
import { getVerses, saveVerse } from "../../actions/verses";
import { Icon } from "semantic-ui-react";
import VerseTrash from "./trash";
import AddVerse from "./add";
import PassageModal from "../passages/passageModal";

const MODAL_NAME = "verseList";

class VerseList extends React.Component {
  state = {
    passageReference: null
  };

  componentDidMount() {
    if (!this.props.userVerses || (!this.props.isLoaded && !this.props.isLoading)) {
      this.props.getVerses();
    }
  };

  handleVerseClick = reference => {
    this.setReference(reference);
  };

  setReference = reference => {
    this.setState(() => {
      return {
        passageReference: reference
      };
    });
  };

  editVerse = (verse, key, value) => {
    verse[key] = value;
    this.props.saveVerse(verse);
  };

  renderActions = verse => {
    if (!this.props.showActions) {
      return null;
    }

    return (
      <td className="right aligned">
        <Icon
          className="clickable"
          size="large"
          name={"bookmark" + (verse.memorization ? "" : " outline")}
          onClick={() =>
            this.editVerse(verse, "memorization", !verse.memorization)
          }
        />
        <Icon
          className="clickable"
          size="large"
          name={"heart" + (verse.favorite ? "" : " outline")}
          onClick={() => this.editVerse(verse, "favorite", !verse.favorite)}
        />
        <VerseTrash verse={verse} />
      </td>
    );
  };

  renderVerseItems = () => {
    const filter =
      this.props.filter ||
      function() {
        return true;
      };

    return this.props.userVerses
      .sort((a, b) => {
        if (a.sort_id < b.sort_id) return -1;
        if (a.sort_id > b.sort_id) return 1;
        return 0;
      })
      .filter(filter)
      .map(verse => {
        return (
          <tr className="" key={verse.id}>
            <td
              className="clickable"
              onClick={() => this.handleVerseClick(verse.reference)}
            >
              {verse.reference}
            </td>
            {this.renderActions(verse)}
          </tr>
        );
      });
  };

  render() {
    if (!this.props.userVerses) {
      return <div />;
    }

    const active = this.props.isLoaded ? "" : "active";
    const addVerse = this.props.showActions ? <AddVerse /> : null;

    return (
      <div>
        <div className={active + " ui inverted dimmer"}>
          <div className="ui text loader">Loading</div>
        </div>
        {addVerse}
        <table className="ui selectable celled compact very basic unstackable table">
          <tbody>{this.renderVerseItems()}</tbody>
        </table>
        <PassageModal
          name={MODAL_NAME}
          reference={this.state.passageReference}
          onClose={() => this.setReference(null)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ verses, auth }) => {
  return {
    userVerses: verses.userVerses,
    isLoaded: verses.isLoaded,
    isLoading: verses.isLoading,
    auth
  };
};

export default connect(
  mapStateToProps,
  { getVerses, saveVerse }
)(VerseList);
