import React from "react";
import { Popup } from "semantic-ui-react";

export default function RefreshHeader(props) {
  let header;
  const classes = "clickable" + (props.className ? " " + props.className : "");

  switch (props.as) {
    case "h1":
      header = <h1 className={classes} onClick={props.onClick}> {props.title} </h1>;
      break;

      case "h2":
        header = <h2 className={classes} onClick={props.onClick}> {props.title} </h2>;
        break;

      case "h3":
        header = <h3 className={classes} onClick={props.onClick}> {props.title} </h3>;
        break;
      
      default:
        header =  <h1 className={classes} onClick={props.onClick}> {props.title} </h1>;
  }
  
  return (
    <Popup trigger={header} content="Refresh data on this page" position='bottom left' />
  );
}