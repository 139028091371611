import {
  GET_AFFIRMATIONS,
  ADD_AFFIRMATION,
  LOADING_AFFIRMATIONS,
  DELETE_AFFIRMATION,
  SAVE_AFFIRMATION
} from "./types";
import { AFFIRMATIONS_URL, client as appApi } from "../api/app";

export const setAffirmationsLoaded = (isLoaded, isLoading) => {
  return {
    type: LOADING_AFFIRMATIONS,
    payload: { isLoaded, isLoading }
  };
};

export const getAffirmations = () => async dispatch => {
  dispatch({
    type: LOADING_AFFIRMATIONS,
    payload: { isLoaded: false, isLoading: true }
  });

  const response = await appApi.get(AFFIRMATIONS_URL);

  dispatch({
    type: GET_AFFIRMATIONS,
    payload: response.data.data
  });
};

export const addAffirmation = affirmation => async dispatch => {
  dispatch({
    type: ADD_AFFIRMATION,
    payload: affirmation
  });
};

export const saveAffirmation = (affirmation, store = false) => async dispatch => {
  dispatch({
    type: SAVE_AFFIRMATION,
    payload: affirmation
  });

  if (store) {
    affirmation["_method"] = "PUT";
    await appApi.post(`${AFFIRMATIONS_URL}/${affirmation.id}`, affirmation);
  }
};

export const deleteAffirmation = affirmation => async dispatch => {
  dispatch({
    type: DELETE_AFFIRMATION,
    payload: affirmation
  });

  await appApi.post(`${AFFIRMATIONS_URL}/${affirmation.id}`, { _method: "DELETE" });
};
