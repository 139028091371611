import { UPDATE_PASSAGE_VIEW, PURGE } from "../actions/types";

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PASSAGE_VIEW:
      const viewData = { ...state };
      viewData[action.payload.name] = action.payload.data;
      return viewData;

    case PURGE:
      return {
        ...INITIAL_STATE
      }

    default:
      return state;
  }
};
