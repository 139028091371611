import React from "react";
import { Modal, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { deletePrayer } from "../../actions/prayers";

class PrayerItemTrash extends React.Component {
  handleDelete = () => {
    this.props.deletePrayer(this.props.prayer);
  };

  render() {
    return (
      <Modal
        trigger={
          <Icon className="clickable" size="large" name="trash alternate" />
        }
        actions={[
          "Cancel",
          {
            key: "delete",
            content: "Delete",
            color: "red",
            onClick: this.handleDelete
          }
        ]}
        header={`Delete Prayer "${this.props.prayer.item}"?`}
        content="Are you sure you want to delete this prayer from your saved prayers?"
      />
    );
  }
}

export default connect(
  null,
  { deletePrayer }
)(PrayerItemTrash);
