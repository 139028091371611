import {
  GET_TASKS,
  ADD_TASK,
  LOADING_TASKS,
  PURGE,
  DELETE_TASK,
  SAVE_TASK,
} from "../actions/types";
import { REHYDRATE } from "redux-persist/lib/constants";

const INITIAL_STATE = {
  tasks: [],
  isLoaded: false,
  isLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.items) {
        return {
          ...state,
          ...action.payload,
          isLoaded: state.isLoaded
        };
      } else {
        return state;
      }

    case PURGE:
      return {
        ...INITIAL_STATE
      };

    case LOADING_TASKS:
      return {
        ...state,
        isLoaded: action.payload.isLoaded,
        isLoading: action.payload.isLoading
      };

    case GET_TASKS:
      return {
        ...state,
        tasks: action.payload,
        isLoaded: true,
        isLoading: false
      };

    case ADD_TASK:
      return {
        ...state,
        tasks: [...state.tasks, action.payload]
      };

    case DELETE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter(item => {
          return item.id !== action.payload.id;
        })
      };

    case SAVE_TASK:
      return {
        ...state,
        tasks: state.tasks.map(item => {
          if (item.id === action.payload.id) {
            item = action.payload;
          }

          return item;
        })
      };

    default:
      return state;
  }
};
