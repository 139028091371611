import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import affirmationsReducer from "./affirmationsReducer";
import versesReducer from "./versesReducer";
import proverbReducer from "./proverbReducer";
import passagesReducer from "./passagesReducer";
import passageViewReducer from "./passageViewReducer";
import prayersReducer from "./prayersReducer";
import tasksReducer from "./tasksReducer";
import currentTasksReducer from "./currentTasksReducer";

export default combineReducers({
  affirmations: affirmationsReducer,
  auth: authReducer,
  currentTasks: currentTasksReducer,
  form: formReducer,
  passages: passagesReducer,
  passageView: passageViewReducer,
  prayers: prayersReducer,
  proverb: proverbReducer,
  tasks: tasksReducer,
  verses: versesReducer
});
