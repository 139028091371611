export const PURGE = "PURGE";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const GET_AFFIRMATIONS = "GET_AFFIRMATIONS";
export const ADD_AFFIRMATION = "ADD_AFFIRMATION";
export const LOADING_AFFIRMATIONS = "LOADING_AFFIRMATIONS";
export const DELETE_AFFIRMATION = "DELETE_AFFIRMATION";
export const SAVE_AFFIRMATION = "SAVE_AFFIRMATION";
export const MOVE_AFFIRMATION = "MOVE_AFFIRMATION";

export const GET_VERSES = "GET_VERSES";
export const ADD_VERSE = "ADD_VERSE";
export const DELETE_VERSE = "DELETE_VERSE";
export const SAVE_VERSE = "SAVE_VERSE";
export const LOADING_VERSES = "LOADING_VERSES";
export const SAVE_PASSAGE = "SAVE_PASSAGE";
export const UPDATE_PASSAGE_VIEW = "UPDATE_PASSAGE_VIEW";

export const GET_PROVERB = "GET_PROVERB";
export const GET_PROVERBS = "GET_PROVERBS";
export const LOADING_PROVERB = "LOADING_PROVERB";
export const LOADING_PROVERBS = "LOADING_PROVERBS";
export const SET_PROVERB = "SET_PROVERB";

export const GET_PRAYERS = "GET_PRAYERS";
export const ADD_PRAYER = "ADD_PRAYER";
export const LOADING_PRAYERS = "LOADING_PRAYERS";
export const DELETE_PRAYER = "DELETE_PRAYER";
export const SAVE_PRAYER = "SAVE_PRAYER";
export const MOVE_PRAYER = "MOVE_PRAYER";

export const GET_TASKS = "GET_TASKS";
export const ADD_TASK = "ADD_TASK";
export const LOADING_TASKS = "LOADING_TASKS";
export const DELETE_TASK = "DELETE_TASK";
export const SAVE_TASK = "SAVE_TASK";

export const LOADING_CURRENT_TASKS = "LOADING_CURRENT_TASKS";
export const GET_CURRENT_TASKS = "GET_CURRENT_TASKS";
export const SAVE_CURRENT_TASK = "SAVE_CURRENTTASK";
