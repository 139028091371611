import React from "react";
import { TermsOfUse as Content } from "../content/termsOfUse";

class TermsOfUse extends React.Component {
  render() {
    return <Content />;
  }
}

export default TermsOfUse;
