import React from "react";
import { connect } from "react-redux";
import { getTasks, saveTask } from "../../actions/tasks";
import { Grid, Segment } from "semantic-ui-react";
import DeleteTask from "./DeleteTask";
import EditTask from "./EditTask";

class TaskList extends React.Component {
  componentDidMount() {
    if (!this.props.tasks || (!this.props.tasks.isLoaded && !this.props.tasks.isLoading)) {
      this.props.getTasks();
    }
  }
  
  renderTaskItems = () => {
    return this.props.tasks.tasks.map((item, i) => {
      return (
        <Grid.Row key={item.id}>
          <Grid.Column>
              <DeleteTask task={item} />
              <EditTask onClick={() => this.props.handleEdit(item)} />
            {item.name}
          </Grid.Column>
        </Grid.Row>
      );
    });
  };

  renderContent = () => {
    if (!this.props.tasks.tasks || this.props.tasks.isLoading) {
      return (
        <div className="active ui inverted dimmer">
          <div className="ui text loader">Loading</div>
        </div>
      );
    } else {
      return (
        <Grid columns={1}>
          {this.renderTaskItems()}
        </Grid>
      );
    }
  };

  render() {
    return (
      <Segment style={{ minHeight: "100px" }}>{this.renderContent()}</Segment>
    );
  }
}

const mapStateToProps = ({ tasks, auth }) => {
  return { tasks, auth };
};

export default connect(
  mapStateToProps,
  { getTasks, saveTask }
)(TaskList);
