import { LOGIN, PURGE } from "./types";
import { client as appApi } from "../api/app";

export const signIn = data => {
  return {
    type: LOGIN,
    payload: {
      userId: data.user_id,
      email: data.email,
      tokenId: data.token_id,
      accessToken: data.access_token,
      name: data.name,
      isSignedIn: true
    }
  };
};

export const signOut = tokenId => async dispatch => {
  try {
    await appApi.logout(tokenId);
  } catch (error) {
    console.log(error);
  }

  dispatch({
    type: PURGE
  });
};
