import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAffirmations } from "../../actions/affirmations";
import { Segment, Grid } from "semantic-ui-react";
import AffirmationItemTrash from "./trash";
import EditAffirmationItem from "./edit";

const renderAffirmationItems = (affirmations, isLoading, handleEdit) => {
  if (!affirmations || isLoading) {
    return (
      <div className="active ui inverted dimmer">
        <div className="ui text loader">Loading</div>
      </div>
    );
  } else {
    const items = affirmations.sort((a, b) => a.affirmation < b.affirmation ? -1 : 1).map((item, i) => {
      return (
        <Grid.Row key={item.id}>
          <Grid.Column width={11}>
              {/* <Icon name="gem" /> */}
              {item.affirmation}
          </Grid.Column>
          <Grid.Column width={5} textAlign="right">
            <div className="extra-vertical-padding">
              <EditAffirmationItem onClick={() => handleEdit(item)} />
              <AffirmationItemTrash affirmation={item} />
            </div>
          </Grid.Column>
        </Grid.Row>
      );
    });

    return  <Grid columns={2} divided="vertically">{items}</Grid>
  }
}

export default function(props) {
  const affirmations = useSelector(state => state.affirmations.items);
  const isLoading = useSelector(state => state.affirmations.isLoading);
  const isLoaded = useSelector(state => state.affirmations.isLoaded);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      dispatch(getAffirmations());
    }
  }, []);

  return (
    <Segment style={{ minHeight: "100px" }}>
        {renderAffirmationItems(affirmations, isLoading, props.handleEdit)}
    </Segment>
  );
}
