import React from "react";
import RedirectUnauthorized from "../common/redirectUnauthorized";
import { connect } from "react-redux";
import { getAffirmations } from "../../actions/affirmations";
import { Button } from "semantic-ui-react";
import AffirmationList from "../affirmations/list";
import ItemModal from "../affirmations/itemModal";
import RefreshHeader from "../common/refreshHeader";

class Affirmations extends React.Component {
  state = {
    modalState: {
      open: false,
      item: {
        order: 100
      }
    }
  };

  closeModal = () => {
    this.setState({ modalState: { open: false } });
  };

  openModal = data => {
    this.setState({
      modalState: {
        ...data,
        open: true
      }
    });
  };

  editItem = item => {
    this.openModal({
      type: "edit",
      item: {
        order: 100,
        ...item
      }
    });
  };

  refresh = () => {
    this.props.getAffirmations();
  };

  render() {
    return (
      <div>
        <RedirectUnauthorized />

        <div>
          <RefreshHeader title="Affirmations" onClick={this.refresh} />

          <div className="ui aligned small header">
            <Button onClick={() => this.openModal({ type: "new" })}>
              Add Item
            </Button>
          </div>

          <AffirmationList
            handleEdit={item => {
              this.openModal({ type: "edit", item });
            }}
          />

          
        </div>
        <ItemModal
          open={this.state.modalState.open}
          type={this.state.modalState.type}
          handleClose={this.closeModal}
          initialValues={this.state.modalState.item}
        />
      </div>
    );
  }
}

export default connect(
  null,
  { getAffirmations }
)(Affirmations);
