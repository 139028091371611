import React from "react";
import { connect } from "react-redux";
import { Button, Modal, Checkbox } from "semantic-ui-react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { addTask, saveTask, setTasksLoaded } from "../../actions/tasks";
import { client as appApi, TASKS_URL } from "../../api/app";
import { getCurrentTasks } from "../../actions/currentTasks";

class TaskModal extends React.Component {
  onSubmit = async formValues => {
    try {
      this.props.setTasksLoaded(false);
      const { id } = formValues;
      const url = TASKS_URL + (id ? "/" + id : "");

      if (id) {
        formValues["_method"] = "PUT";
      }

      const response = await appApi.post(url, formValues);

      if (id) {
        this.props.saveTask(response.data.data);
      } else {
        this.props.addTask(response.data.data);
      }

      this.props.getCurrentTasks();
      this.props.reset();
      this.props.handleClose();
    } catch (error) {
      this.props.setTasksLoaded(true);
      throw new SubmissionError({ _error: "Couldn't add Task." });
    }
  };

  renderInput = ({ input, placeholder, meta, type, label }) => {
    const inputField =
      type === "textarea" ? (
        <textarea {...input} placeholder={placeholder} />
      ) : (
        <input {...input} type={type} placeholder={placeholder} />
      );

    return (
      <div className="ui fluid field">
      <label>{label}</label>
        {inputField}
        {this.renderError(meta)}
      </div>
    );
  };

  renderCheckbox = ({ input, meta, label }) => {
    return (
      <div className="inline field">
        <Checkbox name={input.name} label={label} checked={!!input.value} onChange={(e, data) => input.onChange(data.checked)} />
      </div>
    );
  };

  renderInputSelect = ({ input, meta, options, label }) => {
    const optionTags = options.map((item, i) => {
      return <option key={i} value={item.value}>{item.name}</option>;
    })

    const inputField = (
        <select className="ui compact selection dropdown" {...input}>
          {optionTags}
        </select>
      );

    return (
      <div className="fluid field">
        <label>{label}</label>
        {inputField}
        {this.renderError(meta)}
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderSubmitError(error) {
    if (error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  render() {
    const title = this.props.type === "new" ? "Add Task" : "Edit Task";
    const submitLabel = this.props.type === "new" ? "Add" : "Save";

    const activities = [
      {name:'Daily Proverb',value:'wisdom'},
      {name:'Pray', value:'pray'},
      {name:'Review Verses', value:'memorize'},
      {name:"Read", value:"read"},
      {name:"Affirmations", value:"affirmations"},
      {name:"Custom activity", value:"custom"},
      {name:"Custom link", value:"link"},
    ];

    return (
      <div>
        <Modal open={this.props.open} onClose={this.props.handleClose}>
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <form
                className="ui form error"
                onSubmit={this.props.handleSubmit(this.onSubmit)}
                autoComplete="off"
              >
                <Field
                  name="activity"
                  component={this.renderInputSelect}
                  options={activities}
                  label="Task Activity Type"
                />
                <Field
                  name="name"
                  component={this.renderInput}
                  type="text"
                  placeholder="Task Name"
                  label="Task Name"
                />
                <Field
                  name="link"
                  component={this.renderInput}
                  type="text"
                  placeholder="http://betransformed.app/"
                  label="URL Link (required for Reading and Link types, ignored for others)"
                />
                <Field
                  name="active"
                  component={this.renderCheckbox}
                  label="Active"
                />
                {this.renderSubmitError(this.props.error)}
              </form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.props.handleClose}>Cancel</Button>
            <Button primary onClick={this.props.handleSubmit(this.onSubmit)}>
              {submitLabel}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const connector = connect(
  null,
  { addTask, saveTask, setTasksLoaded, getCurrentTasks }
)(TaskModal);

export default reduxForm({
  form: "addTask",
  enableReinitialize: true
})(connector);
